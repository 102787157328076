<template>
  <div>
    <data-modal
        id="modal-message"
        v-if="selectedMessage"
        :message="selectedMessage"
    />
    <filters v-if="statuses" :event-hub="eventHub" :statuses="statuses" />
    <b-row>
      <b-col>
        <b-card>
          <b-card-actions
              ref="loadingCard"
              action-refresh
          >
            <div class="overflow-x-scroll">
              <b-table
                  :fields="customsMessageFields"
                  :items="customsMessages"
                  show-empty
              >

                <template #cell(status)="data">
                  <b-badge pill variant="primary">{{ data.item.status }}</b-badge>
                </template>

                <template #cell(type)="data">
                  <b-badge
                      pill
                      variant="primary"
                      v-b-modal.modal-message
                      @click="selectedMessage = data.item"
                  >
                    {{ data.item.type }}
                  </b-badge>
                </template>

                <template #cell(at)="data">
                  <strong :class="data.item.class">{{ data.item.at | formatDate }}</strong>
                </template>

              </b-table>
            </div>
            <pagination
                :event-hub="eventHub"
                :per-page="perPage"
            />
          </b-card-actions>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BBadge,
} from 'bootstrap-vue'
import Vue from 'vue'
import shipmentModel from '@/views/shipments/shipmentModel'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filters from '@/views/customs_log/Filters.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import handleError from '@/views/components/errorHandler'
import DataModal from '@/views/customs_log/modals/dataModal.vue'

export default {
  components: {
    DataModal,
    Pagination,
    Filters,
    BTable,
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardActions,
  },
  data() {
    return {
      eventHub: new Vue(),
      selectedMessage: {},
      customsMessageFields: [
        'tracking_number',
        'document_number',
        'io',
        'type',
        'lrn',
        'mrn',
        'status',
        'at',
      ],
      page: 1,
      perPage: 30,
      sortBy: 'at',
      isSortDirDesc: true,
      filters: {

      },
      customsMessages: [],
      statuses: [],
    }
  },
  created() {
    this.eventHub.$on('updateData', data => {
      if (data) {
        this.tableDataStore = Object.values(data)
      }
    })
    this.eventHub.$on('updateFilters', data => {
      this.filters = data
      this.fetchData()
    })
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
    this.fetchData()
    this.fetchStatuses()
  },
  computed: {
    searchQuery() {
      let url = '/v1/customs/messages'

      // Apply page
      url += `?page=${this.$data.page}`
      url += `&per_page=${this.$data.perPage}`

      // Apply filters
      if (Object.keys(this.$data.filters).length > 0) {
        Object.keys(this.$data.filters).forEach(key => {
          if (this.$data.filters[key] !== null && !this.$data.filters[key].value) {
            url += `&${key}=${this.$data.filters[key]}`
          } else if (this.$data.filters[key] !== null && this.$data.filters[key].value) {
            url += `&${key}=${this.$data.filters[key].value}`
          }
        })
      }

      // Apply sorting
      url += `&sortBy=${this.$data.sortBy}`
      url += `&sortDesc=${this.$data.isSortDirDesc}`

      return url
    },
    computedTableData() {
      return this.tableDataStore
    },
  },
  methods: {
    fetchStatuses() {
      this.$http.get('/v1/customs/messages/statuses')
        .then(response => {
          this.statuses = response.data
        })
    },
    getShipment() {
      const cachedData = shipmentModel().getCachedShipment(this.$attrs.shipmentId)
      if (cachedData === null) {
        shipmentModel().getShipment(this.$attrs.shipmentId, this)
          .then(response => {
            shipmentModel().cacheShipment(response.data)
          })
      }
    },
    fetchData() {
      if (this.$refs.loadingCard) {
        this.$refs.loadingCard.showLoading = true
      }
      // Request
      this.$http.get(this.searchQuery)
        .then(response => {
          if (this.$refs.loadingCard) {
            this.$refs.loadingCard.showLoading = false
          }
          this.$data.customsMessages = response.data.data
          this.$data.eventHub.$emit('updateData', response.data.data)
          this.$data.eventHub.$emit('updateMeta', response.data.meta)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style scoped>

</style>
