import axios from '@/libs/axios'
import handleError from '@/views/components/errorHandler'
import store from '@/store'

export default function shipmentModel() {
  const clearCache = (id = null) => {
    if (id) {
      delete store.state.app.shipmentCache[id]
    } else {
      store.state.app.shipmentCache = {}
    }
  }

  const cacheShipment = data => {
    store.state.app.shipmentCache[data.id] = data
  }

  const getCachedShipment = id => {
    if (store.state.app.shipmentCache[id]) {
      return store.state.app.shipmentCache[id]
    }

    return null
  }

  const getShipment = (id, ctx) => {
    const ax = axios.get(`/v1/companies/${ctx.$activeCompany().data.company.id}/shipments/${id}`)
      .catch(error => {
        handleError(error, this.$toast)
      })
    return ax
  }

  return {
    getShipment,
    clearCache,
    cacheShipment,
    getCachedShipment,
  }
}
