<template>

  <b-row>
    <b-col>
      <b-card title="Filters">
        <b-form v-on:keyup.enter="filterData">

          <b-row>
            <b-col>
              <b-form-group
                label="Parcel tracking number"
                label-for="tracking-number"
              >
                <b-form-input
                  id="parcel-tracking-number"
                  v-model="filters.tracking_number"
                  name="tracking-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Document number"
                label-for="document-number"
              >
                <b-form-input
                  id="document-number"
                  v-model="filters.document_number"
                  name="document-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Date range"
                label-for="date-range"
              >
                <flat-pickr
                  id="date-range"
                  v-model="filters.dateRange"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="selectedFilterStatus"
                  :options="statusOptions"
                  :clearable="false"
                  input-id="status"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="IO"
                label-for="io"
              >
                <v-select
                  v-model="filters.io"
                  :options="suggestionListIo"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Type"
                label-for="type"
              >
                <b-form-input
                  id="type"
                  v-model="filters.type"
                  name="type"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="LRN"
                label-for="lrn"
              >
                <b-form-input
                  id="lrn"
                  v-model="filters.lrn"
                  name="lrn"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="MRN"
                label-for="mrn"
              >
                <b-form-input
                  id="mrn"
                  v-model="filters.mrn"
                  name="mrn"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Company name filter -->
            <b-col>
              <b-form-group
                label="Company"
                label-for="company"
              >
                <v-select
                  v-model="filters.companyIds"
                  multiple
                  :reduce="option => option.value"
                  label="label"
                  :options="suggestionList"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Body"
                label-for="body"
              >
                <b-form-input
                  id="body"
                  v-model="filters.body"
                  name="body"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              lg="4"
              md="4"
            >
              <b-button
                class="mt-2"
                type="button"
                variant="primary"
                @click="filterData()"
              >
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import companyModel from '@/views/company/companyModel'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BCard,
    vSelect,
  },
  props: ['eventHub', 'statuses'],
  data() {
    return {
      suggestionListIo: [
        { label: 'Incoming', value: 'incoming' },
        { label: 'Outgoing', value: 'outgoing' },
      ],
      companyList: [],
      filters: {
        io: '',
        type: '',
        lrn: '',
        mrn: '',
        body: '',
        tracking_number: '',
        document_number: '',
        status: '',
        dateRange: '',
        companyIds: getSelectedCompanies(),
      },
      selectedFilterStatus: null,
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    statusOptions() {
      const statuses = []
      statuses.push({
        label: 'N/A',
        value: '',
      })
      this.statuses.forEach(item => {
        statuses.push({
          label: item,
          value: item,
        })
      })
      return statuses
    },
  },
  created() {
    companyModel().getCompanies()
      .then(response => {
        this.$data.companyList = response.data.data
      })
  },
  methods: {
    filterData() {
      if (this.selectedFilterStatus) {
        this.filters.status = this.selectedFilterStatus.value
      }
      this.eventHub.$emit('updateFilters', parseFilters(this.filters))
      updateSelectedCompanies(this.filters.companyIds)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
