import axios from '@/libs/axios'
import handleError from '@/views/components/errorHandler'

export default function companyModel() {
  const getCompanies = () => {
    const ax = axios.get('/v1/companies')
      .catch(error => {
        handleError(error, this.$toast)
      })
    return ax
  }

  const getCompaniesLike = searchQuery => {
    const ax = axios.get(`/v1/companies?q=${searchQuery}`)
      .catch(error => {
        handleError(error, this.$toast)
      })
    return ax
  }

  return {
    getCompanies,
    getCompaniesLike,
  }
}
