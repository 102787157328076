<template>
  <b-modal
      :id="this.$attrs.id"
      ref="view-message-modal"
      title="View message"
      size="lg"
      scrollable
  >
    <b-card-text>
      <!-- IO -->
      <b-row v-if="message.io" class="mb-1">
        <b-col cols="3">
          <label>IO</label>
        </b-col>
        <b-col>{{ message.io }}</b-col>
      </b-row>
      <!-- Type -->
      <b-row v-if="message.type" class="mb-1">
        <b-col cols="3">
          <label>Type</label>
        </b-col>
        <b-col>{{ message.type }}</b-col>
      </b-row>

      <!-- LRN -->
      <b-row v-if="message.lrn" class="mb-1">
        <b-col cols="3">
          <label>LRN</label>
        </b-col>
        <b-col>{{ message.lrn }}</b-col>
      </b-row>

      <!-- MRN -->
      <b-row v-if="message.mrn" class="mb-1">
        <b-col cols="3">
          <label>MRN</label>
        </b-col>
        <b-col>{{ message.mrn }}</b-col>
      </b-row>

      <!-- Status -->
      <b-row v-if="message.status" class="mb-1">
        <b-col cols="3">
          <label>Status</label>
        </b-col>

        <b-col>
          <b-badge pill variant="primary">{{ message.status }}</b-badge>
        </b-col>
      </b-row>

      <!-- Status info -->
      <b-row v-if="message.status_info" class="mb-1">
        <b-col>
          <label>Status info</label>
          <b-form-textarea
              :value="JSON.stringify(message.status_info)"
              :disabled="true"
              rows="5"
              class="mr-0 ml-0"
          />
        </b-col>
      </b-row>

      <!-- Message body -->
      <b-row v-if="message.body" class="mb-1">
        <b-col>
          <label>Message body</label>
          <b-form-textarea
            id="message_body"
            v-model="message.body"
            :disabled="true"
            class="mr-0 ml-0"
            rows="20"
          />
        </b-col>
      </b-row>

      <!-- Message response -->
      <b-row v-if="message.response" class="mb-1">
        <b-col>
          <label>Message response</label>
          <b-form-textarea
            id="message_response"
            v-model="message.response"
            :disabled="true"
            class="mr-0 ml-0"
            rows="20"
          />
        </b-col>
      </b-row>
    </b-card-text>

    <template #modal-footer="{ ok }">

      <b-container fluid>
        <b-row>
          <b-col class="text-right">
            <b-button
                variant="primary"
                @click="ok()"
            >
              Done
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BBadge,
  BCol,
  BContainer,
  BRow,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BBadge,
    BCol,
    BContainer,
    BRow,
    BModal,
    BCardText,
    BFormTextarea,
  },
  props: ['message'],
}
</script>
